import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Accordion, Button } from '/src/components/common'

const accordionItems = [
  {
    answer: '<p>Orrcon Steel was formed from the merger of Welded Tube Mills and Hills Tubing division in October 2000. In February 2005, Hills Holdings acquired 100% of the Orrcon Steel company and in 2007 we acquired by the Impressive Group from Western Australia. In 2010 we added the SteelBarn business which included the acquisition of five branches in QLD. In February 2014, BlueScope Steel acquired Orrcon Steel from Hills Holdings. A rich history in the steel industry.</p>',
    question: "What is your company's history and experience in the steel industry?"
  },
  {
    answer: '<p>Orrcon Steel has two state-of-the-art Australian manufacturing sites - A structural tubular mill in Brisbane and a precision tubing mill in Adelaide.</p>',
    question: 'Where are your products manufactured?'
  },
  {
    answer: '<p>Orrcon steel serves a range of industries including construction, manufacturing, mining, infrastructure and agricultural.</p>',
    question: 'What Industries does Orrcon Steel supply?'
  },
  {
    answer: '<p>Yes, Orrcon Steel offers online ordering through its website. Customers can browse products, place orders, and track their deliveries online. Simply add your items to the shopping cart and select delivery or pickup. Credit, Visa, and PayPal are all accepted, or if you are an account customer you can utilise your trade account.</p>',
    question: 'Does Orrcon Steel offer online ordering?'
  },
  {
    answer: '<p>Yes, Orrcon Steel offers delivery services to customers across Australia via our national distribution network. Delivery charges may vary depending on your location and the size of your order.</p>',
    question: 'Do you deliver?'
  },
  {
    answer: '<p>Depending on the size, intricacy, and material availability of the order, the lead time may change. We work hard to send our products to customers in a timely and effective manner.</p>',
    question: "What's the lead time for orders?"
  },
  {
    answer: '<p>Yes, some of our products have minimum order quantities. For more details, please contact us.</p>',
    question: "Do you have a minimum order requirement?"
  },
  {
    answer: '<p>Orrcon Steel is committed to delivering high-quality steel products that meet Australian and international standards. Their products undergo rigorous testing and quality control processes to ensure they meet the required specifications. <a href="/resources/certificates">View certificates</a></p>',
    question: 'What is the quality of Orrcon Steel products?'
  },
  {
    answer: '<p>Yes, Orrcon Steel has a team of experienced technical experts who can provide advice and support to customers. They also offer a range of technical resources and information on their website.</p>',
    question: 'Does Orrcon Steel offer technical support & advice?'
  },
  {
    answer: '<p>Yes, we also work in partnership with fabricators to tackle more complex customer requirements. Call our customer service team on 1300 677 266 to find out more.</p>',
    question: 'Do you cut to length?'
  },
  {
    answer: '<p>You can contact Orrcon Steel by visiting our website at www.orrconsteel.com.au or by calling our customer service team at 1300 677 266. You can also visit one of our many locations throughout Australia.</p>',
    question: 'How can I get in touch with Orrcon Steel?'
  },
  {
    answer: '<p>Yes, Orrcon Steel is committed to sustainable practices and operates under strict environmental management systems. They have also implemented a range of initiatives to reduce their environmental impact, such as using recycled steel in their products and reducing energy consumption in their operations.</p>',
    question: 'Is Orrcon Steel environmentally responsible?'
  }
]

const FAQPage = () => {
  return (
    <Layout hero={<HeroImage title="FAQ" />} mainContainer={{fluid: "xl"}}>
      <Row className="mb-5">
        <Col sm={12}>
          <p>Please find below a list of frequently asked questions.</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Accordion items={accordionItems} />
        </Col>
      </Row>
    </Layout>
  )
}

export default FAQPage

export const Head = () => (
  <>
    <title>FAQ | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)
